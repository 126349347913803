<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }} #{{ clientId }}
      </h3>
    </div>

    <div class="box-body">
      <div class="row" v-if="client">
        <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div class="box box-primary">
            <div class="box-body">
              <h5 class="box-title pointer" v-on:click="toggleFormClient">
                <a class="collapsed">
                  <i v-bind:class="show_form_client ? 'fa-caret-square-up' : 'fa-caret-square-down'"
                     class="font-scale-3 text-blue fas padding-r-5"/>
                  <strong>Информация</strong>
                </a>
              </h5>

              <transition name="fade">
                <div class="collapse" v-bind:class="show_form_client ? 'in' : ''" v-show="show_form_client"
                     v-show-slide="show_form_client">

                  <ul class="list-group list-group-unbordered">
                    <li class="list-group-item font-scale-1">
                      <strong>Телефон</strong>
                      <span class="pull-right">
                        <i class="fa fa-phone-square font-small"></i>
                        <a :href="'tel:+' + client.phone" class="font-medium">
                          {{ client.phone }}
                        </a>
                      </span>
                    </li>
                    <li v-if="client.phone_two" class="list-group-item font-scale-1">
                      <strong>Телефон #2</strong>
                      <span class="pull-right">
                        <i class="fa fa-phone-square font-small"></i>
                        <a :href="'tel:+' + client.phone_two" class="font-medium">
                          {{ client.phone_two }}
                        </a>
                      </span>
                    </li>
                    <li v-if="client.phone_three" class="list-group-item font-scale-1">
                      <strong>Телефон #3</strong>
                      <span class="pull-right">
                        <i class="fa fa-phone-square font-small"></i>
                        <a :href="'tel:+' + client.phone_three" class="font-medium">
                          {{ client.phone_three }}
                        </a>
                      </span>
                    </li>
                    <li v-if="client.email" class="list-group-item font-scale-1">
                      <strong>Email</strong>
                      <span class="pull-right">
                        <i class="fas fa-envelope font-small"></i>
                        <a :href="'mailto:' + client.email" class="font-medium font-small">
                          {{ client.email }}
                        </a>
                      </span>
                    </li>
                    <li v-if="client.passport" class="list-group-item">
                      <strong>Паспорт</strong>
                      <span class="pull-right">
                        <strong>{{ client.passport }}</strong>
                      </span>
                    </li>
                    <li v-if="client.driver_license" class="list-group-item">
                      <strong>Водительские права</strong>
                      <span class="pull-right">
                        <strong>{{ client.driver_license }}</strong>
                      </span>
                    </li>
                    <li v-if="client.city || client.address" class="list-group-item">
                      <strong>Адрес</strong>

                      <a class="pull-right">
                        <strong v-if="client.city">{{ client.city.name }}</strong>
                        <small v-if="client.address"> {{ client.address }}</small>
                      </a>
                    </li>
                    <li v-if="client.partner" class="list-group-item">
                      <strong>Приглашен партнером</strong>
                      <span class="pull-right">
                        <strong>{{ client.partner.name }}</strong> (ID {{ client.partner.id }})
                      </span>
                    </li>
                  </ul>

                  <hr class="mb-2 mt-2"/>

                  <p class="text-muted text-right">
                    <small v-if="client.creator">
                      Ответственный <strong>{{ client.creator.name }}</strong>
                    </small>
                    <small v-if="client.editor">
                      <br/>
                      Обновил <strong>{{ client.editor.name }}</strong>
                      {{ client.updated_date }}
                    </small>
                    <small>
                      <br/>
                      Создан <strong>{{ client.created_date }}</strong>
                    </small>
                  </p>
                </div>
              </transition>
            </div>
          </div>

        </div>

        <div class="col-xxl-9 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <vue-tabs class="panel no-border" v-model="currentTabItem" @tab-change="handleTabChange">
            <v-tab class="nav-tab-content"
                   key="history"
                   :title="tabNames.history">
              <client-history :clientId="client.id"/>
            </v-tab>
            <v-tab class="nav-tab-content"
                   key="files"
                   :title="tabNames.files">
              <client-files v-if="currentTabItem === 1"
                            :clientId="client.id"/>
            </v-tab>
            <v-tab class="nav-tab-content"
                   key="cars"
                   :title="tabNames.cars">
              <client-cars v-if="currentTabItem === 2"
                           :clientId="client.id"/>
            </v-tab>
            <v-tab class="nav-tab-content"
                   key="tasks"
                   :title="tabNames.tasks">
              <client-tasks v-if="currentTabItem === 3"
                            :clientId="client.id"/>
            </v-tab>
            <v-tab class="nav-tab-content"
                   key="calls"
                   :title="tabNames.calls">
                            <client-calls v-if="currentTabItem === 4"
                                        :clientId="client.id"/>
            </v-tab>
          </vue-tabs>
        </div>
      </div>
      <loading-processing v-else></loading-processing>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {VTab, VueTabs} from 'vue-nav-tabs';
import 'vue-nav-tabs/themes/vue-tabs.css';

Vue.component('client-history', (resolve) => {
  return require(['./history'], resolve);
});
Vue.component('client-files', (resolve) => {
  return require(['./files'], resolve);
});
Vue.component('client-cars', (resolve) => {
  return require(['./cars'], resolve);
});
Vue.component('client-tasks', (resolve) => {
  return require(['./tasks'], resolve);
});
Vue.component('client-calls', (resolve) => {
  return require(['./calls'], resolve);
});
export default {
  name: 'client-details',

  components: {
    VueTabs,
    VTab,
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
    currentTab: {
      type: [String, null],
      required: false,
      default: null,
    },
  },

  data: () => ({
    page_title: 'Клиент',

    client: null,

    relations: [
      'city',
      'partner',
      'creator',
      'editor',
    ],
    relations_count: [
      'history',
      'files',
      'cars',
      'tasks',
      'calls'
    ],

    tabNames: {
      history: 'История',
      files: 'Файлы',
      cars: 'Автомобили',
      tasks: 'Задачи',
      calls: 'Звонки',
    },

    currentTabItem: null,

    show_form_client: true,
  }),

  async created() {
    try {
      if (this.currentTab) {
        this.show_form_client = false;

        this.currentTabItem = this.currentTab;
      }

      await this.getClient();
    } catch (error) {
      errorHandler(error);
    }
  },

  mounted() {
    setTimeout(() => {
      this.tabNames.history += ' (' + this.client.history_count + ')';
      this.tabNames.files += ' (' + this.client.files_count + ')';
      this.tabNames.cars += ' (' + this.client.cars_count + ')';
      this.tabNames.tasks += ' (' + this.client.tasks_count + ')';
      this.tabNames.calls += ' (' + this.client.calls_count + ')';
    }, 1500);
  },

  methods: {
    handleTabChange(tabIndex, newTab, oldTab){
      this.currentTabItem = tabIndex;
    },
    async toggleFormClient() {
      this.show_form_client = this.show_form_client === false;
    },
    async getClient() {
      try {
        const client_response = await API.apiClient.get('/client/' + this.clientId, {
          params: {
            relations: this.relations,
            relations_count: this.relations_count,
          }
        });

        this.client = client_response.data;
        this.page_title = this.client.name;
      } catch (error) {
        errorHandler(error);
      }
    }
  }
}
</script>

<style scoped>

</style>
